.App {
  background: rgb(43, 43, 43);
  background: radial-gradient(circle, rgba(40,40,40,1) 0%, rgba(20,20,20,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.Logo {
  background: linear-gradient(0deg, rgb(213, 53, 0) 0%, rgb(255, 234, 1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Subtitle {

}
